<template>
  <Modal v-model="isShowModal" :closable="false" title="转出提示" @on-ok="ok">
    <div style="margin: 25px">此操作不可逆，是否继续操作？</div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isShowModal: false,
      company_id: 0,
      salary_amount: 0,
      service_amount: 0,
    };
  },
  methods: {
    ...mapActions({
      assetTransferOutAction: "assetTransferOut",
    }),
    open(company_id, salary_amount, service_amount) {
      this.company_id = company_id;
      this.salary_amount = salary_amount;
      this.service_amount = service_amount;
      this.isShowModal = true;
    },
    ok() {
      this.assetTransferOutAction({
        employer_id: localStorage.getItem("employer_id"),
        company_id: this.company_id,
        salary_amount: this.salary_amount,
        service_amount: this.service_amount,
      })
        .then((res) => {
          this.$Message.success("转出成功");
          this.$parent.$parent.refresh();
          this.isShowModal = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.asset-out-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 25px 50px 30px 50px;
}
</style>