<template>
  <div class="asset-out-mission-root-container">
    <img
      :src="icons.tongbi"
      style="width: 45px; height: 45px; margin-left: 20px"
    />
    <div class="asset-out-mission-main-container">
      <div class="asset-out-mission-success-title">
        <span
          style="
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
          :title="dataSource.STT_VCH_RCV_AC_NAME"
          >{{ dataSource.STT_VCH_RCV_AC_NAME }}</span
        >
        <span
          style="
            font-size: 12px;
            color: #dddddd;
            width: 60px;
            margin-left: 10px;
          "
          >支付成功</span
        >
      </div>
      <div style="font-size: 12px; color: #e8e8e8">
        🎉恭喜您完成支付，您现在可以向账户添加¥{{
          amountFormat
        }}资产,其中包含服务费¥{{ serviceFormat }}「游用工卡」
      </div>
      <div class="asset-out-mission-btn" @click="showDetail">点击</div>
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import { numberFormat } from "../../../utils/numberHelper";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
  methods: {
    showDetail() {
      this.$emit("show-detail");
    },
  },
  computed: {
    amountFormat: function () {
      let format = numberFormat(
        this.dataSource.salary_amount + this.dataSource.service_amount
      );
      return format.integer + "." + format.decimal;
    },
    serviceFormat: function () {
      let format = numberFormat(this.dataSource.service_amount);
      return format.integer + "." + format.decimal;
    },
  },
};
</script>

<style scoped>
.asset-out-mission-root-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #426b61;
  border-radius: 13px;
  min-width: 440px;
  height: 120px;
  margin-right: 15px;
}
.asset-out-mission-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  margin-left: 30px;
  margin-right: 40px;
}
.asset-out-mission-success-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.asset-out-mission-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 12px;
  color: #838383;
  cursor: pointer;
  width: 60px;
}
</style>