<template>
  <Modal v-model="isShowModal" footer-hide :closable="false" :width="500">
    <div class="asset-receive-modal-root-container" v-if="isShowModal">
      <img
        :src="icons.ysLogo"
        style="width: 120px; height: 80px; align-self: center; margin-top: 40px"
      />
      <span
        style="
          font-size: 23px;
          font-weight: bold;
          color: #3f3f3f;
          margin-top: 20px;
          margin-bottom: 10px;
          align-self: center;
        "
        >{{ dataSource.name }}</span
      >
      <fee
        :amount="dataSource.salary_amount"
        :primarySize="32"
        :secondarySize="29"
        style="margin-bottom: 50px; align-self: center"
      />
      <div class="asset-receive-modal-row">
        <span class="asset-receive-modal-key">付款时间</span>
        <span class="asset-receive-modal-val">{{ createTime }}</span>
      </div>
      <div class="asset-receive-modal-row">
        <span class="asset-receive-modal-key">付款方式</span>
        <span class="asset-receive-modal-val">{{ dataSource.pay_method }}</span>
      </div>
      <span class="asset-receive-modal-key" style="margin-bottom: 20px"
        >计入账簿</span
      >
      <div class="asset-receive-modal-card-container">
        <img
          :src="icons.assetCardLogo"
          style="
            width: 50px;
            height: 64px;
            margin-left: 20px;
            margin-right: 30px;
          "
        />
        <span
          style="flex: 1; font-size: 18px; font-weight: bold; color: #000000"
          >预付款/卡</span
        >
      </div>
      <div class="asset-receive-modal-confirm-btn" @click="close">确认接收</div>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
import { mapActions } from "vuex";
export default {
  components: {
    fee: Fee,
  },
  data() {
    return {
      isShowModal: false,

      icons: icon,
      dataSource: {},
    };
  },
  methods: {
    ...mapActions({
      assetGotitAction: "assetGotit",
    }),
    open(dataSource) {
      this.dataSource = dataSource;
      this.isShowModal = true;
    },
    close() {
      this.assetGotitAction({
        employer_id: localStorage.getItem("employer_id"),
        notice_id: this.dataSource.notice_id,
      })
        .then((res) => {
          this.isShowModal = false;
          this.$parent.showSuccessModal(this.dataSource);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
  computed: {
    createTime: function () {
      return moment(this.dataSource.create_time).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.asset-receive-modal-root-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  padding: 25px 50px;
}
.asset-receive-modal-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.asset-receive-modal-key {
  color: #716f6f;
  font-size: 15px;
  font-weight: 500;
}
.asset-receive-modal-val {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}
.asset-receive-modal-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  border-radius: 14px;
  background-color: #f9f6d8;
}
.asset-receive-modal-confirm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #04a073;
  color: #ffffff;
  border-radius: 40px;
  width: 140px;
  height: 55px;
  font-size: 21px;
  font-weight: 500;
  margin-top: 60px;
  margin-bottom: 30px;
  cursor: pointer;
}
</style>