<template>
  <div class="asset-new-root-container">
    <div class="asset-head-container">
      <div style="display: flex; flex-direction: column">
        <div class="page-title">游用工卡</div>
        <!-- <div class="asset-card-modal-sub-title">
          @游隼企业管理(山东)有限公司
        </div> -->
        <Button v-if="type == 1" style="width: 100px; margin-top: 20px" type="success" size="large" :loading="loading"
          :disabled="loading" @click="createAccount">开户</Button>
        <div v-else class="asset-info-container">
          <span style="color: #7b7b7b; font-size: 12px">您可以向下面的账户付款以增加游用工卡的余额</span>
          <span style="font-weight: bold">{{ cardInfo.issuer }}</span>
          <span style="color: #7b7b7b; font-size: 12px; margin-top: 5px">
            {{ cardInfo.bank_name }}
            <span style="margin-left: 20px">{{ cardInfo.num }}</span>
          </span>
        </div>
      </div>
      <!-- <div class="page-title">游用工卡（山东）</div>
      <div>@游隼企业管理(山东)有限公司</div>
      <Button
        v-if="type == 1"
        style="width: 100px; margin-top: 20px"
        type="success"
        size="large"
        :loading="loading"
        :disabled="loading"
        @click="createAccount"
        >开户</Button
      >
      <div v-else class="asset-card-modal-info-container">
        <span style="color: #7b7b7b; font-size: 12px"
          >您可以向下面的账户付款以增加游用工卡的余额</span
        >
        <span style="font-weight: bold">游隼企业管理(山东)有限公司</span>
        <span style="color: #7b7b7b; font-size: 12px; margin-top: 5px">
          {{ cardInfo.bank_name }}
          <span style="margin-left: 20px">{{ cardInfo.num }}</span>
        </span>
      </div> -->
      <div class="asset-new-wallet-container">
        <img :src="icons.wallet" style="width: 70px; height: 55px" />
        <div style="font-size: 32px; font-weight: bold; margin-top: 10px">
          ￥{{ totalAmountFormat.integer }}.<span style="font-size: 28px">{{
            totalAmountFormat.decimal
          }}</span>
        </div>
        <div class="frozen-amount">
          其中税费：
          <fee style="color: gray !important" :amount="balance.service_amount" :primarySize="12" :secondarySize="12" />
        </div>
        <div class="frozen-amount">
          已冻结：
          <fee style="color: gray !important" :amount="balance.frozen_amount" :primarySize="12" :secondarySize="12" />
        </div>
        <div class="frozen-amount">
          净可用金额：
          <fee style="color: gray !important" :amount="balance.salary_amount" :primarySize="12" :secondarySize="12" />
        </div>
        <span>账户资产以人民币 🇨🇳 计价</span>
      </div>
    </div>

    <div class="asset-new-datepicker-container">
      <span style="margin-left: 20px"> 开始时间： </span>
      <DatePicker transfer type="date" v-model="start_time" placeholder="选择日期" style="width: 200px"></DatePicker>
      <span style="margin-left: 20px"> 结束时间： </span>
      <DatePicker transfer type="date" v-model="end_time" placeholder="选择日期" style="width: 200px"></DatePicker>
      <Button style="margin-left: 20px" @click="submit" type="primary">提交</Button>
    </div>

    <div class="asset-new-notification-container">
      <div class="scroll-warper">
        <span v-if="notificationList.length == 0" style="font-size: 12px">🎉 恭喜您，没有任何未完成的任务</span>
        <asset-out-mission v-else v-for="item in notificationList" :key="item.notice_id" :dataSource="item"
          @show-detail="showReceiveModal(item)" />
      </div>
    </div>

    <slide-tabbar :tabs="tabs" @on-change="onBarChange" show-bar />
    <div class="asset-company-list-container" v-if="currentIndex == 0">
      <asset-company v-for="item in companyList" :key="item.company_id" :data-source="item" />
    </div>
    <div class="asset-company-list-container" v-if="currentIndex == 1">
      <asset-company v-for="(item, index) in transferOutList" :key="'out-' + index" :type="2" :data-source="item" />
    </div>

    <asset-receive-modal ref="assetReceiveModal" />
    <asset-success-modal ref="assetSuccessModal" />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import * as icons from "../../common/icon";
import FeeVue from "../../components/Fee.vue";
import AssetCompanyVue from "./Item/AssetCompany.vue";
import { numberFormat } from "../../utils/numberHelper";
import SlideTabbarVue from "../../components/SlideTabbar.vue";
import AssetOutMissionVue from "../Asset/Item/AssetOutMission.vue";
import AssetReceiveModalVue from "../Asset/Modal/AssetReceiveModal.vue";
import AssetSuccessModalVue from "../Asset/Modal/AssetSuccessModal.vue";
export default {
  components: {
    fee: FeeVue,
    "slide-tabbar": SlideTabbarVue,
    "asset-company": AssetCompanyVue,
    "asset-out-mission": AssetOutMissionVue,
    "asset-receive-modal": AssetReceiveModalVue,
    "asset-success-modal": AssetSuccessModalVue,
  },
  data() {
    return {
      icons: icons,
      loading: false,
      type: 1, // 1:未开户 2:开户

      currentIndex: 0,

      cardInfo: {
        bank_name: "",
        num: "",
      },
      balance: {
        salary_amount: 0,
        service_amount: 0,
        frozen_amount: 0,
        update_time: "---",
      },

      tabs: [
        {
          name: "公司列表",
        },
        {
          name: "转出记录",
        },
      ],
      companyList: [],
      transferOutList: [],
      notificationList: [],

      start_time: null,
      end_time: null,
    };
  },
  mounted() {
    this.refresh();
    this.getCompanyList();
  },
  computed: {
    totalAmountFormat() {
      return numberFormat(
        this.balance.salary_amount +
          this.balance.service_amount +
          this.balance.frozen_amount
      );
    },
  },
  methods: {
    ...mapActions({
      assetGetNoticeAction: "assetGetNotice",
      assetGetBalanceAction: "assetGetBalance",
      employerCreateAccountAction: "employerCreateAccount",
      assetGetTransferOutListAction: "assetGetTransferOutList",
      companyGetCompanyAssetListAction: "companyGetCompanyAssetList",
      assetIsOpenCardByEmployerIdAction: "assetIsOpenCardByEmployerId",
      tempCreateHasStreamIdTempZipAction: "tempCreateHasStreamIdTempZip",
      assetGetCardDetailByEmployerIdAction: "assetGetCardDetailByEmployerId",
    }),
    createAccount() {
      this.loading = true;
      this.employerCreateAccountAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.type = 2;
          this.cardInfo = res;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$Message.error(error);
        });
    },
    onBarChange(index) {
      this.currentIndex = index;
      if (this.currentIndex == 0) {
        this.getCompanyList();
      } else {
        this.getTransferOutList();
      }
    },

    showReceiveModal(item) {
      item.name = "游用工卡";
      item.pay_method = "银行转账";
      this.$refs.assetReceiveModal.open(item);
    },
    getCompanyList() {
      this.companyGetCompanyAssetListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.companyList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getTransferOutList() {
      this.assetGetTransferOutListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.transferOutList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    refresh() {
      this.getCompanyList();

      this.getTransferOutList();

      this.assetIsOpenCardByEmployerIdAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.type = res.is_open ? 2 : 1;
          if (this.type == 2) {
            this.assetGetCardDetailByEmployerIdAction({
              employer_id: localStorage.getItem("employer_id"),
            })
              .then((res) => {
                this.cardInfo = res;
              })
              .catch((error) => {
                console.error(error);
                this.$Message.error(error);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });

      this.assetGetBalanceAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.balance = {
            salary_amount: res.salary_amount,
            service_amount: res.service_amount,
            frozen_amount: res.frozen_amount,
            update_time: moment(res.update_time).format("YYYY/MM/DD HH:mm"),
          };
        })
        .catch((error) => {
          this.$Message.error(error);
        });

      this.assetGetNoticeAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.notificationList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    showSuccessModal(dataSource) {
      this.refresh();

      this.$refs.assetSuccessModal.open(dataSource);
    },
    submit() {
      if (this.start_time == null || this.start_time == "") {
        this.$Message.warning("请选择开始时间");
        return;
      }
      if (this.end_time == null || this.end_time == "") {
        this.$Message.warning("请选择结束时间");
        return;
      }

      this.tempCreateHasStreamIdTempZipAction({
        start_time: this.start_time,
        end_time: this.end_time,
      })
        .then((res) => {
          this.$Message.success(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.asset-new-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 150px;
  background-color: #f9f9f9;
}
.asset-head-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.asset-info-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.asset-new-wallet-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.asset-company-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 30px;
}
.asset-new-notification-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #f3f3f3;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  overflow-x: scroll;
}
.scroll-warper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.asset-new-datepicker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>