<template>
  <Modal v-model="isShowModal" footer-hide :closable="false" :width="500">
    <div class="asset-success-modal-root-container">
      <Icon type="ios-checkmark-circle" size="100" color="#04a073" />
      <span style="font-size: 23px; font-weight: bold; margin: 25px 0">资产添加成功</span>
      <Divider />
      <div style="display: flex; flex-direction: row; align-items: center">
        <my-avatar :name="dataSource.name" :img_url="dataSource.img_url" :size="35" />
        <span style="font-size: 23px; font-weight: bold; margin-left: 10px">{{
          dataSource.name
        }}</span>
      </div>
      <fee :amount="dataSource.salary_amount" :primarySize="32" :secondarySize="29"
        style="margin-top: 20px; margin-bottom: 50px; align-self: center" />
      <div class="asset-success-modal-btn" @click="close">关闭</div>
    </div>
  </Modal>
</template>

<script>
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      isShowModal: false,

      dataSource: {
        name: "",
        img_url: "",
        salary_amount: 0,
      },
    };
  },
  methods: {
    open(dataSource) {
      this.dataSource = dataSource;
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.asset-success-modal-root-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 25px 50px;
}
.asset-success-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #ebebeb;
  color: #000000;
  border-radius: 40px;
  width: 140px;
  height: 55px;
  font-size: 21px;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>