<template>
  <div class="asset-company-root-container">
    <div
      class="asset-company-left-container"
      style="justify-content: space-between; align-items: center"
    >
      <my-avatar
        :name="dataSource.name"
        :img_url="dataSource.img_url"
        :size="40"
        style="margin-right: 10px"
      />
      <span
        v-if="type == 1"
        style="font-size: 12px; cursor: pointer; color: #0099ff"
        @click.stop="transOut"
        >转出</span
      >
    </div>
    <div
      class="asset-company-right-container"
      style="align-items: flex-end"
      v-if="type == 1"
    >
      <div style="font-size: 15px; font-weight: bold; color: #000000">
        {{ dataSource.name }}
      </div>
      <div style="color: #969696; font-size: 12px; margin-top: 10px">
        当前总资产
      </div>
      <fee :amount="totalAmount" :primarySize="24" :secondarySize="20" />

      <div
        style="
          display: flex;
          align-items: center;
          color: #969696;
          font-size: 12px;
          margin-top: 10px;
        "
      >
        其中税费：
        <fee
          style="color: #969696"
          :amount="dataSource.total_service_amount"
          :primarySize="14"
          :secondarySize="12"
        />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          color: #969696;
          font-size: 12px;
          margin-top: 10px;
        "
      >
        已冻结：
        <fee
          style="color: #969696"
          :amount="dataSource.frozen_amount"
          :primarySize="14"
          :secondarySize="12"
        />
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-color: #857c7c;
          border-radius: 5px;
          padding: 5px 10px;
          margin-top: 5px;
        "
      >
        <div style="color: #e6e6e6; font-size: 12px">净可用金额：</div>
        <fee
          style="color: #ffffff"
          :amount="dataSource.total_salary_amount"
          :primarySize="14"
          :secondarySize="12"
        />
      </div>
    </div>
    <div
      class="asset-company-right-container"
      style="justify-content: space-between; align-items: flex-end"
      v-else
    >
      <div style="font-size: 15px; font-weight: bold; color: #000000">
        {{ dataSource.name }}
      </div>
      <div>
        <div
          style="
            color: #969696;
            font-size: 12px;
            margin-top: 10px;
            text-align: right;
          "
        >
          转出金额
        </div>
        <fee :amount="totalAmount" :primarySize="24" :secondarySize="20" />
      </div>
    </div>

    <asset-out-modal ref="assetOutModal" />
  </div>
</template>

<script>
import FeeVue from "../../../components/Fee.vue";
import AssetOutModalVue from "../Modal/AssetOutModal.vue";
import MyAvatarVue from "../../../components/MyAvatar.vue";
export default {
  components: {
    fee: FeeVue,
    "my-avatar": MyAvatarVue,
    "asset-out-modal": AssetOutModalVue,
  },
  props: {
    dataSource: Object,
    type: {
      type: Number,
      default: 1, // 1 完整显示 2 只显示转出
    },
  },
  computed: {
    totalAmount() {
      if (this.type == 1) {
        return (
          this.dataSource.total_salary_amount +
          this.dataSource.total_service_amount +
          this.dataSource.frozen_amount
        );
      } else {
        return Math.abs(
          this.dataSource.total_salary_amount +
            this.dataSource.total_service_amount
        );
      }
    },
  },
  methods: {
    transOut() {
      // 当前总资产小于等于 0 或者有冻结金额时不允许转出
      if (this.dataSource.frozen_amount != 0) {
        this.$Message.error("有冻结金额无法转出");
        return;
      }
      if (
        this.dataSource.total_salary_amount +
          this.dataSource.total_service_amount <=
        0
      ) {
        this.$Message.error("总资产必须为正数");
        return;
      }
      this.$refs.assetOutModal.open(
        this.dataSource.company_id,
        this.dataSource.total_salary_amount,
        this.dataSource.total_service_amount
      );
    },
  },
};
</script>

<style scoped>
.asset-company-root-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 330px;
  height: 220px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 1px #dddddd;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.asset-company-root-container:hover {
  box-shadow: 0 0px 10px #dddddd;
}
.asset-company-left-container,
.asset-company-right-container {
  display: flex;
  flex-direction: column;
}
</style>